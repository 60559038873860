import React from "react"
import { Box, BoxProps, Flex, useDisclosure } from "@chakra-ui/core"
import { useDispatch, useSelector } from "react-redux"
import { StringParam, useQueryParam } from "use-query-params"
import { v4 as uuid } from "uuid"
import * as R from "ramda"
import { FluidObject } from "gatsby-image"
import * as Storefront from "shopify-storefront-api-typings"

import { ConnectedAddedToCartModal as AddedToCartModal } from "../Cart/AddedToCartModal"
import { getFixedPrice, groupCustomizedLineItems } from "../Cart/utils"
import { Button } from "./Buttons"
import GridSelectPicker from "../GridSelectPicker"
import { Option } from "../GridSelectOption"
import Body from "../typography/Body"
import UnavailableOptionsModal, {
  UnavailableOption,
} from "../UnavailableOptionsModal"
import AccessoryCategoryList, {
  AccessoryCategoryListProps,
} from "./AccessoryCategoryList"
import CustomizerDrawer from "./CustomizerDrawer"
import SelectListItem from "./SelectListItem"
import DetailsItem from "../DetailsItem"
import Notice from "../Notice"
import BackInStockButton from "../BackInStockButton/connected"

import {
  Maybe,
  ContentfulProduct,
  ContentfulProductVariant,
  ContentfulProductCustomizerOptionsJsonNodeColors as CustomizerColors,
  ContentfulBicycleModelSharedCustomizerOptionsJsonNodeAccessoryCategoriesAccessories as SharedCustomizerAccessories,
} from "../../../graphql-types"
import { BaseDispatch, BaseRootState } from "../../redux/store"
import {
  CartItem,
  createCheckoutLineItem,
  mapToLineItemInput,
} from "../../redux/models/checkout"
import { BikeType, CustomizerBikeType } from "redux/types/BikeTypes"
import { ShopifyVariantPricing } from "../../shopify/graphql/queries"
import { isColorWhite, decodeShopifyId } from "../../utils"
import { mergeCustomizerCategories } from "../../utils/customizer"
import {
  getPreorderLabel,
  PreorderInfoResponse,
  useFetchPreorderInfo,
} from "../../utils/preorder"
import { useLazyShopifyVariantPricing } from "../../hooks/useShopifyVariantPricing"
import { getPriceDiff } from "./utils"

type CustomizerOptionsProps = BoxProps

type DrawerType = "Color" | "Speed" | "Accessories" | "Warranty"

type UnavaibleModalConfig = {
  newOptionName: string
  nextCustomizerBike: CustomizerBikeType
  unavailableOptions: UnavailableOption[]
}

const Price = ({ children, color = "#eee", className }) => (
  <Body size="xs" color={color} className={className}>
    {children}
  </Body>
)

const DRAWERS: DrawerType[] = ["Color", "Speed", "Accessories", "Warranty"]

const CustomizerOptions = ({ ...props }: CustomizerOptionsProps) => {
  const [drawer, setDrawer] = React.useState<DrawerType | null>(null)
  const [
    unavailableModal,
    setUnvailableModel,
  ] = React.useState<UnavaibleModalConfig | null>(null)
  const [addedToCart, setAddedToCart] = React.useState(false)
  const addedToCartModal = useDisclosure()

  // Fetch pricing
  const [fetchPricing, { pricingMap }] = useLazyShopifyVariantPricing()
  const {
    data: preorderInfo,
    loading: preorderInfoLoading,
  } = useFetchPreorderInfo()

  const dispatch = useDispatch<BaseDispatch>()
  const {
    bikes: { customizerBike, bikes, warranty },
    checkout: { isLoading: isCartLoading, data: checkoutData },
  } = useSelector((state: BaseRootState) => state)

  const selectedBike = bikes.find(
    ({ title }) => title === customizerBike?.bike?.title
  )

  // Editing cart item
  const [editCartItemId] = useQueryParam("edit", StringParam)
  const editingCartItem = React.useMemo(
    () =>
      editCartItemId
        ? groupCustomizedLineItems(checkoutData?.lineItems?.edges ?? checkoutData?.lines?.edges ?? []).find(
            (item) => item.id === editCartItemId
          )
        : null,
    [editCartItemId, checkoutData]
  )

  // Fetch pricing
  React.useEffect(() => {
    if (customizerBike) {
      const { bike } = customizerBike
      const productIds = R.uniq(
        R.flatten([
          // Variants
          bike.speeds?.map((product) =>
            product?.variants?.map((variant) => variant?.shopifyId)
          ),
          // Shared Accessories
          bike.sharedCustomizerOptions?.accessoryCategories?.map((cat) =>
            cat?.enabled
              ? cat.accessories?.map((acc) => acc?.variant?.shopifyId)
              : []
          ) || [],
          // Speed Accessories
          bike.speeds?.map((speed) =>
            speed?.customizerOptions?.enabled
              ? speed?.customizerOptions?.accessoryCategories?.map((cat) =>
                  cat?.enabled
                    ? cat.accessories?.map((acc) =>
                        acc?.enabled ? acc.variant?.shopifyId : null
                      )
                    : []
                )
              : []
          ),
          // Warranty
          warranty?.shopifyId,
        ])
      ).filter((x) => !!x) as string[]

      fetchPricing(productIds)
    }
  }, [fetchPricing, customizerBike])

  // Show AddedToCartModal after cart is done loading
  React.useEffect(() => {
    if (addedToCart && isCartLoading === false) {
      setAddedToCart(false)
      addedToCartModal.onOpen()
    }
  }, [isCartLoading])

  if (!customizerBike) return null

  const {
    bike: currentBike,
    speed: currentSpeed,
    variant: currentVariant,
    accessories: currentAccessories,
  } = customizerBike

  /** currentSpeeds: Each product with the current speed.
   *
   * A bike can have multiple products with the same speed
   */
  const currentSpeeds = currentBike.speeds?.filter(
    (product) => product?.speed === currentSpeed?.speed
  )
  const accessoryCount = Object.values(currentAccessories || {}).length

  const currentVariantPricing = pricingMap[currentVariant?.shopifyId || ""]

  const findSpeedWithSameColor = (
    colorInternalTitle: string,
    speedValue: string
  ) => {
    const speeds = currentBike.speeds?.filter(
      (speed) => speed?.speed === speedValue
    )
    return speeds?.reduce(
      (acc, speed) => {
        if (acc.speed && acc.variant) return acc
        const variant = speed?.variants?.find(
          (variant) => variant?.color?.internalTitle === colorInternalTitle
        )

        if (variant) {
          return { speed, variant }
        }
        return acc
      },
      { speed: null, variant: null }
    )
  }
  const findColorWithSameSpeed = (
    speedValue: string,
    colorInternalTitle: string
  ) => {
    const speeds = currentBike.speeds?.filter(
      (speed) => speed?.speed === speedValue
    )
    return speeds?.reduce((acc, speed) => {
      const color = speed?.variants?.find(
        (variant) => variant?.color?.internalTitle === colorInternalTitle
      )
      return color || acc
    }, undefined)
  }

  /**
   * Gets the next customizer state if changes to the given speed
   */
  const getNextAccessories = (newSpeed: ContentfulProduct) => {
    const unavailableOptions: UnavailableOption[] = []
    const nextAccessories: typeof customizerBike.accessories = {}
    const nextCategoriesMap = R.indexBy(
      // @ts-ignore
      R.prop("name"),
      mergeCustomizerCategories(
        currentBike.sharedCustomizerOptions?.accessoryCategories,
        newSpeed.customizerOptions?.accessoryCategories
      ) || []
    )

    // Check if current accessories don't fit on new speed
    const addUnavailableAccessory = (accessory: ContentfulProductVariant) => {
      const tooltip = accessory.title || ""
      let image = {}
      if (accessory.productListingImage) {
        image = {
          fluid: accessory.productListingImage?.fluid,
        }
      } else {
        image = {
          color: accessory.color?.hexCode || "dusk",
        }
      }
      unavailableOptions.push({
        tooltip,
        ...image,
      })
    }

    R.forEachObjIndexed(
      (accessory: ContentfulProductVariant, category: string) => {
        // Check if new categories even has the accessory's category
        if (
          nextCategoriesMap[category]?.enabled &&
          nextCategoriesMap[category]?.accessories
        ) {
          // Check if new category contains current accessory
          const isItemInNewCategories = R.find(
            (nextAccessory) =>
              nextAccessory!.variantId === accessory.contentful_id,
            (nextCategoriesMap[category]
              ?.accessories as SharedCustomizerAccessories[]) || []
          )
          if (!isItemInNewCategories || !isItemInNewCategories.enabled) {
            addUnavailableAccessory(accessory)
          } else {
            nextAccessories[category] = accessory
          }
        } else {
          addUnavailableAccessory(accessory)
        }
      },
      currentAccessories
    )

    return { nextAccessories, unavailableOptions }
  }

  const getNextCustomizerBikeWithSpeed = (nextSpeedValue: string) => {
    // First find speed with same color
    const currentColorInternalTitle = currentVariant?.color?.internalTitle || ""
    const newSpeedWithSameColor = findSpeedWithSameColor(
      currentColorInternalTitle,
      nextSpeedValue
    )

    if (newSpeedWithSameColor?.speed) {
      // All is good. Found speed with same color
      const { nextAccessories, unavailableOptions } = getNextAccessories(
        newSpeedWithSameColor.speed
      )
      const nextCustomizerBike = {
        ...customizerBike,
        speed: newSpeedWithSameColor.speed,
        variant: newSpeedWithSameColor.variant,
        accessories: nextAccessories,
      }
      return {
        unavailableOptions,
        nextCustomizerBike,
      }
    } else {
      // New speed doesn't have the same color.
      // Find new speed with any color
      const newSpeed = currentBike.speeds?.find(
        (speed) =>
          speed?.speed === nextSpeedValue && (speed.variants?.length ?? 0) > 0
      )
      if (newSpeed) {
        const nextVariant = newSpeed.variants?.[0]!
        const { nextAccessories, unavailableOptions } = getNextAccessories(
          newSpeed
        )

        // Add warning that the same color is unavailable
        unavailableOptions.push({
          color: currentVariant?.color?.hexCode || "white",
          tooltip: `Color: ${currentVariant?.color?.name || "N/A"}`,
        })

        const nextCustomizerBike = {
          ...customizerBike,
          speed: newSpeed,
          variant: nextVariant,
          accessories: nextAccessories,
        }

        return {
          nextCustomizerBike,
          unavailableOptions,
        }
      }
    }

    return { nextCustomizerBike: null, unavailableOptions: null }
  }

  const getNextCustomizerBikeWithColor = (nextColorInternalTitle: string) => {
    const sameSpeedWithColor = findSpeedWithSameColor(
      nextColorInternalTitle,
      currentSpeed?.speed!
    )

    if (sameSpeedWithColor?.speed) {
      // Found speed with new color
      const { nextAccessories, unavailableOptions } = getNextAccessories(
        sameSpeedWithColor.speed
      )
      return {
        unavailableOptions,
        nextCustomizerBike: {
          ...customizerBike,
          speed: sameSpeedWithColor.speed,
          variant: sameSpeedWithColor.variant,
          accessories: nextAccessories,
        },
      }
    }

    // Current speed doesn't have new color
    // Find any speed with new color
    const speedsWithNewColor = currentBike.speeds?.filter((speed) =>
      speed?.variants?.find(
        (variant) => variant?.color?.internalTitle === nextColorInternalTitle
      )
    )
    if (speedsWithNewColor && speedsWithNewColor.length > 0) {
      //
      const newSpeed = speedsWithNewColor[0]
      const newVariant = newSpeed?.variants?.find(
        (variant) => variant?.color?.internalTitle === nextColorInternalTitle
      )
      const { nextAccessories, unavailableOptions } = getNextAccessories(
        newSpeed!
      )

      unavailableOptions.push({
        tooltip: `${currentSpeed?.speed!} Speed`,
        text: currentSpeed?.speed!,
      })

      return {
        unavailableOptions,
        nextCustomizerBike: {
          ...customizerBike,
          speed: newSpeed,
          variant: newVariant,
          accessories: nextAccessories,
        },
      }
    }

    return {
      unavailableOptions: null,
      nextCustomizerBike: null,
    }
  }

  const uniqueSpeeds = Object.values(
    (currentBike.speeds || [])
      // Consolidate multiples of same speed
      .reduce((acc, product) => {
        if (product?.speed) {
          const speed = acc[product.speed]
          const variant = product.variants?.find(
            (variant) =>
              variant?.color?.internalTitle ===
              currentVariant?.color?.internalTitle
          )
          if (speed) {
            if (!speed.sameColorVariant) {
              return {
                ...acc,
                [product.speed]: { product, sameColorVariant: variant },
              }
            }
            return acc
          } else {
            return {
              ...acc,
              [product.speed]: { product, sameColorVariant: variant },
            }
          }
        }
        return acc
      }, {} as Record<string, { product: ContentfulProduct; sameColorVariant?: ContentfulProductVariant }>)
  )

  const speedOptions = uniqueSpeeds.map(({ product, sameColorVariant }) => {
    const { unavailableOptions } = getNextAccessories(product!)

    return {
      name: `${product?.speed} Speed`,
      value: product?.speed,
      innerText: product?.speed,
      showWarning: !sameColorVariant || unavailableOptions.length > 0,
      price: getPriceDiff(currentVariant!, sameColorVariant!, pricingMap),
    }
  }) as Option[]

  const handleChangeSpeed = (selected: Option) => {
    const speedValue = selected.value
    const {
      nextCustomizerBike,
      unavailableOptions,
    } = getNextCustomizerBikeWithSpeed(speedValue)

    if (nextCustomizerBike === null || unavailableOptions === null) return

    if (unavailableOptions.length === 0) {
      // Everything is good. Update the bike
      dispatch.bikes.setCustomizerBike(nextCustomizerBike)
    } else {
      // Warn user that not everything can be applied
      setUnvailableModel({
        newOptionName: `${speedValue} Speed`,
        nextCustomizerBike,
        unavailableOptions,
      })
    }
  }

  const getColorOptions = (speed: Maybe<ContentfulProduct>) =>
    ((
      speed?.variants?.map((variant) => {
        const colorWithSameSpeed = findColorWithSameSpeed(
          currentSpeed?.speed!,
          variant?.color?.internalTitle!
        )
        const showWarning = !colorWithSameSpeed

        return {
          name: variant?.color?.name,
          value: variant?.color?.internalTitle,
          backgroundColor: variant?.color?.hexCode,
          price: getPriceDiff(currentVariant!, variant!, pricingMap),
          showBorder: isColorWhite(variant?.color?.hexCode || ""),
          showWarning,
        } as Option
      }) || []
    )?.filter((color) => !!color) || []) as Option[]

  // Get all possible colors from other speeds
  const currentSpeedColorOptions = getColorOptions(currentSpeed!)
  const otherSpeeds = currentBike.speeds?.filter(
    (otherSpeed) => otherSpeed?.contentful_id !== currentSpeed?.contentful_id
  )
  const otherColorOptions = R.uniqBy(
    R.prop("name"),
    R.flatten(otherSpeeds?.map((speed) => getColorOptions(speed)) || [])
  )
  const colorOptions = R.unionWith(
    R.eqBy(R.prop("name")),
    currentSpeedColorOptions,
    otherColorOptions
  ).sort((a, b) => {
    return a.name.localeCompare(b.name)
  })

  const handleChangeColor = (selected: Option) => {
    const newColorInternalTitle = selected.value
    const {
      unavailableOptions,
      nextCustomizerBike,
    } = getNextCustomizerBikeWithColor(newColorInternalTitle)

    if (nextCustomizerBike === null || unavailableOptions === null) return

    if (unavailableOptions.length === 0) {
      // Everything is good. Update the bike
      dispatch.bikes.setCustomizerBike(nextCustomizerBike)
    } else {
      // Warn user that not everything can be applied
      setUnvailableModel({
        newOptionName: `${selected.name} Color`,
        nextCustomizerBike,
        unavailableOptions,
      })
    }
  }

  const handleSelectAccessory: AccessoryCategoryListProps["onAccessoryClick"] = (
    category,
    accessory
  ) => {
    const newAccessories = {
      ...currentAccessories,
    }
    if (accessory) {
      newAccessories[category] = accessory
    } else {
      delete newAccessories[category]
    }
    dispatch.bikes.setCustomizerBike({
      ...customizerBike,
      accessories: newAccessories,
    })
  }

  const handleContinueWithUnavailableOptions = () => {
    dispatch.bikes.setCustomizerBike(unavailableModal!.nextCustomizerBike)
    setUnvailableModel(null)
  }

  const handleChangeWarranty = (option: Option) => {
    dispatch.bikes.setCustomizerBike({
      ...customizerBike,
      warranty: option.value === "forever" ? true : false,
    })
  }

  const getCartItems = () => {
    const bundleId = uuid()
    const childCartItems: CartItem[] = []
    Object.values(currentAccessories ?? {})?.forEach((accessory) => {
      const accessoryModel = accessory.accessory_model?.[0]
      childCartItems.push({
        type: "accessory",
        productSlug: accessoryModel?.internalTitle!,
        contentfulProductId: accessoryModel?.contentful_id!,
        contentfulVariantId: accessory?.contentful_id!,
        variantId: accessory?.shopifyId!,
        parentBundleId: bundleId,
        quantity: 1,
      })
    })
    if (customizerBike.warranty) {
      const warrantyModel = warranty?.accessory_model?.[0]
      childCartItems.push({
        type: "warranty",
        productSlug: warrantyModel?.internalTitle || "forever-warranty",
        contentfulProductId: warrantyModel?.contentful_id!,
        contentfulVariantId: warranty?.contentful_id!,
        variantId: warranty?.shopifyId!,
        parentBundleId: bundleId,
        quantity: 1,
      })
    }
    return {
      cartItem: {
        type: "bike",
        productSlug: currentBike.internalTitle!,
        contentfulProductId: currentSpeed?.contentful_id!,
        contentfulVariantId: currentVariant?.contentful_id!,
        variantId: currentVariant?.shopifyId!,
        isPreorder,
        preorderInfo: getPreorderLabel(
          preorderInfo?.data,
          currentVariant?.shopifyId!,
          currentVariantPricing?.product.id
        ),
        bundleId,
        quantity: 1,
      } as CartItem,
      childCartItems,
    }
  }

  const handleAddToCart = () => {
    dispatch.checkout.addToCart(getCartItems())
    setAddedToCart(true)
  }

  const handleUpdateCart = () => {
    if (!editingCartItem) return
    const newCartItems = getCartItems()
    const previousLineItemIds = [
      editingCartItem.id,
      ...editingCartItem.accessories.map((acc) => acc.id),
      editingCartItem.warranty?.id,
    ]
    const updatedItems: any[] = [
      // Other cart items
      ...(
        (checkoutData?.lineItems?.edges || checkoutData?.lines?.edges || []).filter(
          (item) => !previousLineItemIds.includes(item.node.id)
        ) || []
      ).map(mapToLineItemInput),
      // Updated cart items
      createCheckoutLineItem(newCartItems.cartItem),
      ...newCartItems.childCartItems.map((cartItem) =>
        createCheckoutLineItem(cartItem)
      ),
    ]

    dispatch.checkout.replaceCheckoutItems(updatedItems)
    setAddedToCart(true)
  }

  const bikePrice = Number(currentVariantPricing?.priceV2?.amount)
  const accessoriesPrice =
    Object.values(currentAccessories || {}).reduce((total, accessory) => {
      return total + Number(pricingMap[accessory.shopifyId!]?.priceV2?.amount)
    }, 0) || 0
  const warrantyPrice = customizerBike.warranty
    ? Number(pricingMap[warranty?.shopifyId!]?.priceV2?.amount)
    : 0
  const subtotal = bikePrice + accessoriesPrice + warrantyPrice

  const availableForSale = currentVariantPricing?.availableForSale
  const isPreorder =
    currentVariantPricing?.availableForSale &&
    currentVariantPricing?.currentlyNotInStock
  const preorderAccessoryCount = Object.values(currentAccessories || {}).reduce(
    (acc, next) => {
      const accPricing = pricingMap[next.shopifyId!]
      const isAccPreorder =
        accPricing?.availableForSale && accPricing.currentlyNotInStock
      return isAccPreorder ? acc + 1 : acc
    },
    0
  )

  return (
    <Box w="100%" color="dusk" {...props}>
      {speedOptions.length === 0 && (
        <Body color="dusk">Customizer options not available</Body>
      )}
      {speedOptions.length > 0 && (
        <>
          <SelectListItem onClick={() => setDrawer("Speed")}>
            <Body color="#fff" className="CustomizerOptionsTitle">
              Speed
            </Body>
            <Box textAlign="right">
              <Body color="#fff" className="CustomizerOptionsSubAnswers">
                {currentSpeed?.speed}
              </Body>
              <Price color="#eee" className="CustomizerOptionsAnswers">
                {getFixedPrice(bikePrice.toString())}
              </Price>
            </Box>
          </SelectListItem>
          <SelectListItem onClick={() => setDrawer("Color")}>
            <Body color="#fff" className="CustomizerOptionsTitle">
              Color
            </Body>
            <Box
              bg={currentVariant?.color?.hexCode}
              h="1rem"
              w="1rem"
              borderRadius="1rem"
              border="1px solid"
              borderColor={
                isColorWhite(currentVariant?.color?.hexCode || "white")
                  ? "night"
                  : "white"
              }
            />
          </SelectListItem>
          <SelectListItem
            preorderLabel={
              preorderAccessoryCount > 0
                ? `${preorderAccessoryCount} Pre-orders selected`
                : undefined
            }
            onClick={() => setDrawer("Accessories")}
          >
            <Body color="#fff" className="CustomizerOptionsTitle">
              Accessories
            </Body>
            <Box textAlign="right">
              <Body color="#fff" className="CustomizerOptionsSubAnswers">
                {accessoryCount} Selected
              </Body>
              {accessoriesPrice !== 0 && (
                <Price color="#eee" className="CustomizerOptionsAnswers">
                  {getFixedPrice(accessoriesPrice.toString())}
                </Price>
              )}
            </Box>
          </SelectListItem>
          {!currentBike.disableWarranty && (
            <SelectListItem onClick={() => setDrawer("Warranty")}>
              <Body color="#fff" className="CustomizerOptionsTitle">
                Warranty
              </Body>
              <Box textAlign="right">
                <Body color="#fff" className="CustomizerOptionsSubAnswers">
                  {customizerBike.warranty ? "Forever Waranty" : "1 Year"}
                </Body>
                {customizerBike.warranty && (
                  <Price color="#eee" className="CustomizerOptionsAnswers">
                    {getFixedPrice(warrantyPrice.toString())}
                  </Price>
                )}
              </Box>
            </SelectListItem>
          )}
        </>
      )}
      <Flex
        className="subTotalString"
        justify="space-between"
        fontWeight="semibold"
        mt="4.2875rem"
        mb="1.5625rem"
        color="#fff"
      >
        <Body>Subtotal</Body>
        <Body>{getFixedPrice(subtotal.toString())}</Body>
      </Flex>
      {isPreorder && (
        <Notice mb="20">
          {getPreorderLabel(preorderInfo?.data, currentVariant?.shopifyId!)}
        </Notice>
      )}
      {!availableForSale && (
        <>
          <Button w="100%" theme="secondary" isDisabled>
            Sold out
          </Button>
          {/* {currentVariant?.shopifyId && (
            <BackInStockButton
              variantId={decodeShopifyId(currentVariant.shopifyId) || ""}
              bikeTitle={currentBike?.formattedTitle ? currentBike.formattedTitle : ''}
              listName="BackInStock"
              w="100%"
              mt="1rem"
            />
          )} */}
        </>
      )}
      {availableForSale && (
        <Button
          className="QuizButtonWhite addToCart"
          w="100%"
          theme="primary"
          onClick={editCartItemId ? handleUpdateCart : handleAddToCart}
          isLoading={isCartLoading || (isPreorder && preorderInfoLoading)}
        >
          {editCartItemId ? "Update" : isPreorder ? "Pre-order" : "Add to Cart"}
        </Button>
      )}

      {DRAWERS.map((drawerLabel, index) => (
        <CustomizerDrawer
          key={drawerLabel}
          // @ts-ignore
          size="sidebar"
          isOpen={drawer === drawerLabel}
          title={drawerLabel}
          showNext={index !== DRAWERS.length - 1}
          onNextClick={() => {
            setDrawer(DRAWERS[index + 1])
          }}
          onClose={() => setDrawer(null)}
        >
          <CustomizerDrawerContent
            selectedBike={selectedBike}
            drawerLabel={drawerLabel}
            customizerBike={customizerBike}
            currentSpeed={currentSpeed}
            currentColor={currentVariant}
            handleChangeColor={handleChangeColor}
            handleChangeSpeed={handleChangeSpeed}
            handleChangeWarranty={handleChangeWarranty}
            handleSelectAccessory={handleSelectAccessory}
            speedOptions={speedOptions}
            colorOptions={colorOptions}
            warrantyVariant={warranty}
            pricingMap={pricingMap}
            preorderData={preorderInfo?.data}
          />
        </CustomizerDrawer>
      ))}
      <UnavailableOptionsModal
        isOpen={!!unavailableModal}
        isCentered
        newOptionName={unavailableModal?.newOptionName || ""}
        unavailableOptions={unavailableModal?.unavailableOptions}
        onContinue={handleContinueWithUnavailableOptions}
        onClose={() => setUnvailableModel(null)}
      />
      <AddedToCartModal
        modalTitle={editingCartItem ? "Updated!" : "Added to Cart!"}
        isOpen={addedToCartModal.isOpen}
        onClose={addedToCartModal.onClose}
        formattedTitle={currentBike.formattedTitle || ""}
        image={currentVariant?.productListingImage?.fluid as FluidObject}
        details={
          <>
            <DetailsItem>{currentVariant?.color?.name}</DetailsItem>
            <DetailsItem>{currentSpeed?.speed} Speed</DetailsItem>
            <DetailsItem>
              {customizerBike.warranty ? "Forever Warranty" : "1 Year Warranty"}
            </DetailsItem>
          </>
        }
        price={getFixedPrice(subtotal.toString())}
      />
    </Box>
  )
}

const CustomizerDrawerContent = ({
  drawerLabel,
  customizerBike: { bike, accessories, warranty },
  currentSpeed,
  currentColor,
  speedOptions,
  colorOptions,
  warrantyVariant,
  handleChangeSpeed,
  handleChangeColor,
  handleSelectAccessory,
  handleChangeWarranty,
  pricingMap,
  preorderData,
  selectedBike,
}: {
  drawerLabel: DrawerType
  customizerBike: CustomizerBikeType
  currentSpeed: ContentfulProduct | null | undefined
  currentColor: ContentfulProductVariant | null | undefined
  warrantyVariant: ContentfulProductVariant | null
  handleChangeColor: (selected: Option) => void
  handleChangeSpeed: (selected: Option) => void
  handleChangeWarranty: (selected: Option) => void
  handleSelectAccessory: AccessoryCategoryListProps["onAccessoryClick"]
  speedOptions: Option[]
  colorOptions: Option[]
  pricingMap: Record<string, ShopifyVariantPricing>
  preorderData?: PreorderInfoResponse["data"]
  selectedBike?: BikeType | null
}) => {
  switch (drawerLabel) {
    case "Speed":
      return (
        <GridSelectPicker
          options={speedOptions}
          value={currentSpeed?.speed}
          onChange={handleChangeSpeed}
          itemSize="wide"
          swatchProps={{
            height: "3.5625rem",
          }}
          className="BodyFitDrawerSelectOptionBtn"
          py="2rem"
        />
      )
    case "Color":
      return (
        <GridSelectPicker
          options={colorOptions}
          value={currentColor?.color?.internalTitle}
          onChange={handleChangeColor}
          itemSize="wide"
          swatchProps={{
            height: "3.5625rem",
          }}
          columns={2}
          py="2rem"
          className="BodyFitDrawerSelectOptionBtn"
        />
      )
    case "Accessories":
      const sharedCategories = bike.sharedCustomizerOptions?.accessoryCategories
      const specificCategories =
        currentSpeed?.customizerOptions?.accessoryCategories
      const categories = mergeCustomizerCategories(
        sharedCategories,
        specificCategories
      )
      return (
        <AccessoryCategoryList
          categories={categories || []}
          selectedCategories={accessories}
          onAccessoryClick={handleSelectAccessory}
          pricingMap={pricingMap}
          preorderData={preorderData}
          className="BodyFitDrawerSelectOptionList"
        />
      )
    case "Warranty":
      return (
        <GridSelectPicker
          itemSize="large"
          className="BodyFitDrawerSelectOptionWarranty"
          options={
            selectedBike?.type?.includes("Standard")
              ? [
                  {
                    innerText: "1 Year Warranty",
                    name: "Included",
                    value: "1-year",
                  },
                  {
                    innerText: "Forever Warranty",
                    name: getFixedPrice(
                      pricingMap[warrantyVariant?.shopifyId!]?.priceV2?.amount
                    ),
                    value: "forever",
                  },
                ]
              : [
                  {
                    innerText: "1 Year Warranty",
                    name: "Included",
                    value: "1-year",
                  },
                ]
          }
          value={warranty ? "forever" : "1-year"}
          onChange={handleChangeWarranty}
          py="2rem"
        />
      )
    default:
      return null
  }
}

export default CustomizerOptions
